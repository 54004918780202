import { createSlice } from '@reduxjs/toolkit';
import { getLabById, getLaboratorie } from './laboratorieThunk';
const initialState = {
    data: [],
    oneData: undefined,
    loading: false,
    error: null,
    total: 0,
};
const slice = createSlice({
    name: 'laboratorie',
    initialState,
    reducers: {
        clearOneData: (state) => {
            state.oneData = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLaboratorie.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getLaboratorie.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getLaboratorie.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(getLabById.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getLabById.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.oneData = payload?.data?.data;
        })
            .addCase(getLabById.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const { clearOneData } = slice.actions;
